import React from "react";

import { Label, FormGroup } from "reactstrap";

const RadioArray = (props) => {
  const {
    input,
    disabled,
    heading,
    required,
    items,
    label,
    defaultValue,
    meta: { touched, error },
  } = props;

  // let errorComponent, validationState, errorMessage;
  //
  // // If there is an error
  // if (touched && error) {
  //   if (error[0] === '"' && error.slice(1).indexOf('"')) {
  //     errorMessage = `${label || 'This'}${error.substr(
  //       error.slice(1).indexOf('"') + 2
  //     )}`;
  //   }
  //   errorComponent = <div>{errorMessage || error}</div>;
  //   validationState = 'error';
  // }
  //
  // if (valid) {
  //   validationState = 'success';
  // }

  return (
    <FormGroup>
      {heading ? (
        <Label>
          {heading}
          {required ? <span>*</span> : null}
        </Label>
      ) : null}
      <div className={"radio-fieldset"}>
        {items.map((item, i) => (
          <div className="radio-wrap" key={i}>
            <input
              {...input}
              type="radio"
              value={item.value}
              disabled={disabled ? "disabled" : ""}
              checked={
                input.value === item.value || item.value === defaultValue
              }
              className=""
              id={`${input.name}-${item.value}`}
            />{" "}
            <label
              className="radio-button"
              htmlFor={`${input.name}-${item.value}`}
            >
              {item.label}
            </label>
          </div>
        ))}
      </div>
      {touched && error ? <span className="help-block">{error}</span> : null}
    </FormGroup>
  );
};

// RadioArray.propTypes = {
//     input: PropTypes.object.isRequired,
//     // name: PropTypes.string.isRequired,
//     className: PropTypes.string,
//     items: PropTypes.arrayOf(PropTypes.shape({
//         label: PropTypes.string.isRequired,
//         value: PropTypes.any.isRequired,
//     })).isRequired,
//     heading: PropTypes.string,
//     meta: PropTypes.object,
//     required: PropTypes.bool,
//     disabled: PropTypes.bool,
// };

export default RadioArray;
