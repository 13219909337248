// import {material, octicons} from 'styled-icons'
// import * as boxiconsLogos from 'styled-icons/boxicons-logos'
// import * as boxiconsRegular from 'styled-icons/boxicons-regular'
// import * as boxiconsSolid from 'styled-icons/boxicons-solid'
// import * as crypto from 'styled-icons/crypto'
// import * as faBrands from 'styled-icons/fa-brands'
// import * as faRegular from 'styled-icons/fa-solid'
// import * as faSolid from 'styled-icons/fa-solid'

export {
  AddressCard as Profile,
  Ban as Cancelled,
  Bars as Hamburger,
  Bell as Notification,
  Box,
  Check,
  CheckCircle,
  ChevronLeft as LeftArrow,
  Clock as Waiting,
  Cog,
  Couch,
  Edit,
  FileAlt as TaxReceipt,
  Heart as Complete,
  Home as Scheduled,
  LocationArrow,
  MapMarkedAlt as OnRoute,
  Truck as OutForPickup,
  Unlock as Lock,
  UserCircle as User,
  Images,
  WindowClose,
  EllipsisH,
} from "styled-icons/fa-solid";

// import * as feather from 'styled-icons/feather'
// import * as icomoon from 'styled-icons/icomoon'
// import * as material from 'styled-icons/material'
// import * as octicons from 'styled-icons/octicons'
// import * as typicons from 'styled-icons/typicons'

// export const Profile = AddressCard
// export const Notification = Bell
// export const Scheduled = Home
// export const Edit = Edit
// export const TaxReceipt = FileAlt
// export const Complete = Heart
// export const Cancelled = Ban
// export const Hamburger = Bars
// export const OutForPickup = Truck
// export const OnRoute = MapMarkedAlt
// export const User = UserCircle
// export const Lock = Unlock
// export const Check = Check
// export const CheckCircle = CheckCircle
// export const Cog = Cog
// export const Waiting = Clock
// export const LeftArrow = ChevronLeft
//
//
// // export default faRegular
